import React from 'react';
import './new.css';

export default function New() {
  return (
    <div>
      <button className='card'>
        <div className='row clearfix'>
          <div className='left big'>+92 331 577 7782</div>
          <div className='right'>
            <p>
              <span className='big'>H</span>ashir &amp;{' '}
              <span className='big'>B</span>rothers
            </p>
            <p>
              <span className='med no-space'>S</span>
              <span className='small no-space'>ervices and </span>
              <span className='med no-space'>C</span>
              <span className='small no-space'>onsultancy</span>
            </p>
          </div>
        </div>
        <div className='row'>
          <p>
            <span className='big'>Syed Najib Shah</span>
          </p>
          <p>najeebworkmail@gmail.com</p>
        </div>
        <div className='row'>
          <p>
            <span className='med'>I</span>
            <span className='small'>slamabad </span>
            <span className='med'>, P</span>
            <span className='small'>akistan</span>
            <span className='med'>, 44000</span>
          </p>
        </div>
      </button>
    </div>
  );
}
